import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  button: {
    marginTop: 10,
    marginBottom: 20,
    marginRight: 10,
    height: 50,
    backgroundColor: '#ff810d',
  },
  bill: {
    // display: 'none',
    width: '13cm',
    height: '7.5cm',
    fontSize: '12px',
    lineHeight: 1.5,
    padding: '8px',
    paddingLeft: '22px',
    paddingRight: '12px',
  },
  section: {
    display: 'flex',
    padding: '4px',
    borderBottom: '1px solid',
  },
  bodySection: {
    display: 'flex',
    padding: '4px',
    height: '150px',
    borderBottom: '1px solid',
  },
  lastSection: {
    display: 'flex',
    padding: '4px',
  },
  textInfo: {
    fontSize: '16px',
    margin: 0,
  },
  senderInfo: {
    fontSize: '14px',
    margin: 0,
  },
  textBody: {
    fontSize: '42px',
    margin: 0,
  },
  textSub: {
    fontSize: '30px',
    margin: 0,
  },
  flexItem: {
    flex: 1,
  },
  flexItem20: {
    flex: '0 0 20%',
  },
  flexItem40: {
    flex: '0 0 40%',
  },
  centerAlign: {
    textAlign: 'center',
  },
  rightAlign: {
    textAlign: 'right',
    paddingRight: '8px',
  },
  headerImage: {
    width: '50px',
    height: '50px',
  },
  textLine32: {
    margin: '4px 0',
    lineHeight: '40px',
  },
  textLine24: {
    margin: '4px 0',
    lineHeight: '24px',
  },
}))
