import React from 'react'
import moment from 'moment'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import Login from './app/Login'
import Main from './app/Main'

moment.locale('lo')
const theme = createTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Noto Sans Lao'],
  },
  palette: {
    primary: {
      main: '#00b9eb',
      light: '#c1e8f3',
      dark: '#7d7deb',
      contrastText: '#000000',
    },
    secondary: {
      main: '#2f3678',
      light: '#c1e8f3',
      dark: '#7d7deb',
      contrastText: '#000000',
    },
    error: {
      main: '#A21C2B',
    },
  },
})

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" render={() => <Main />} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  )
}

export default App
