import React from 'react'
import Joi from 'joi'
import { Button, Container, Typography } from '@material-ui/core'
import { useForm } from 'react-final-form-hooks'
import moment from 'moment'
import { TextInput, SelectInput } from '../../components'
import validate from '../../service/form/validation'
import formCreateInputs from '../../service/form/create'
import singhaLogo from '../../assets/singha_mono.png'
import halExpressLogo from '../../assets/logistics/hal_express_mono.png'
import mixayExpressLogo from '../../assets/logistics/mixay_express_mono.png'
import anousithExpressLogo from '../../assets/logistics/anousith_express_mono.png'
import kriengkaiExpressLogo from '../../assets/logistics/kriengkai_express_mono.png'
import useStyles from './styles'

export default () => {
  const classes = useStyles()

  const onSubmit = () => {
    setTimeout(() => {
      const head = `
      <html>
        <head>
          <title>ຂວັນໃຈ 10,000 ກີບ</title>
          <style>
            @media print {
              @page {
                margin: 0; /* Remove default browser margins */
              }
              body {
                margin: 0;
                padding: 0;
              }
            }
          </style>
        </head>
        <body>
    `
      const foot = `</body></html>`
      const inner = document.getElementById('logistic-bill').innerHTML

      if (inner) {
        document.body.innerHTML = head + inner + foot
        window.print()
        window.close()
        window.location.reload(true)
      } else {
        console.error("Element with id 'logistic-bill' not found!")
      }
    })
  }

  const schema = Joi.object().keys({
    sendName: Joi.string().required(),
    sendPhone: Joi.string().required(),
    receiveNamePhone: Joi.string().required(),
    logistics: Joi.string().required(),
    manualLogistics: Joi.string().allow(null, ''),
    address: Joi.string().required(),
  })

  const { form, handleSubmit, submitting, values } = useForm({
    onSubmit,
    initialValues: {
      sendName: 'Singha Logistics',
      sendPhone: '77798280',
      receiveNamePhone: '',
      logistics: '',
      manualLogistics: '',
      address: '',
    },
    validate: validate(schema),
  })
  const [
    sendName,
    sendPhone,
    receiveNamePhone,
    logistics,
    manualLogistics,
    address,
  ] = formCreateInputs(
    ['sendName', 'sendPhone', 'receiveNamePhone', 'logistics', 'manualLogistics', 'address'],
    form,
  )

  const getExpressLogo = value => {
    switch (value) {
      case 'ຮຸ່ງອາລຸນ (HAL Express)':
        return halExpressLogo
      case 'ມີໄຊ (Mixay Express)':
        return mixayExpressLogo
      case 'ອານຸສິດ (Anousith Express)':
        return anousithExpressLogo
      case 'ກຽງໄກ (Kriengkai)':
        return kriengkaiExpressLogo
      default:
        return ''
    }
  }
  return (
    <Container className={classes.root} maxWidth="sm">
      <Typography component="h1" variant="h5">
        ພິມບິນຂົນສົ່ງ
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextInput input={sendName} label="ຊື່ຜູ້ສົ່ງ" />
        <TextInput input={sendPhone} label="ເບີຜູ້ສົ່ງ" />
        <TextInput input={receiveNamePhone} label="ຊື່ຜູ້ຮັບ ແລະ ເບີໂທ" />
        <SelectInput
          label="ບໍລິສັດຂົນສົ່ງ"
          input={logistics}
          options={[
            'ຮຸ່ງອາລຸນ (HAL Express)',
            'ມີໄຊ (Mixay Express)',
            'ອານຸສິດ (Anousith Express)',
            'ກຽງໄກ (Kriengkai)',
            'ອື່ນໆ',
          ]}
        />
        {values.logistics === 'ອື່ນໆ' && <TextInput input={manualLogistics} label="ຊື່ບໍລິສັດ" />}
        <TextInput input={address} label="ທີ່ຢູ່ສາຂາ" />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          disabled={submitting}
          className={classes.button}
        >
          ດຳເນີນການ
        </Button>
      </form>
      <div id="logistic-bill" className={classes.bill}>
        <div className={classes.section}>
          <div className={classes.flexItem20}>
            <img src={singhaLogo} alt="Singha Logistics" className={classes.headerImage} />
          </div>
          <div className={`${classes.flexItem40} ${classes.centerAlign}`}>
            <p className={classes.textInfo}>
              ວັນທີ່: <b>{moment().format('lll')}</b>
            </p>
          </div>
          <div className={`${classes.flexItem40} ${classes.rightAlign}`}>
            <p className={classes.senderInfo}>
              ສົ່ງຈາກ: <b>{values.sendName}</b>
            </p>
            <p className={classes.senderInfo}>
              Tel: <b>{values.sendPhone}</b>
            </p>
          </div>
        </div>

        <div className={classes.bodySection}>
          <div className={`${classes.flexItem} ${classes.textLine32}`}>
            <h4 className={classes.textBody}>ສົ່ງ: {values.receiveNamePhone}</h4>
            <p className={classes.textSub}>ສາຂາ: {values.address}</p>
          </div>
        </div>

        <div className={classes.lastSection}>
          <div className={classes.flexItem}>
            <p className={classes.textInfo}>
              ຂົນສົ່ງ: {values.manualLogistics || values.logistics}
            </p>
            <p className={classes.textInfo}>ເກັບຄ່າຂົນສົ່ງປາຍທາງ (ຖ່າຍວິດີໂອກ່ອນແກະກ່ອງ)</p>
          </div>
          <div className={`${classes.flexItem20} ${classes.rightAlign}`}>
            {getExpressLogo(values.logistics) && (
              <img
                src={getExpressLogo(values.logistics)}
                alt={values.logistics}
                className={classes.headerImage}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
