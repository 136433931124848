import React, { useEffect } from 'react'
import store from 'store'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import http from '../../service/http'
import { Layout } from '../../components'
import PageNotFound from '../../components/notfound'
import ProductChina from '../ProductChina'
import HistoryChina from '../HistoryChina'
import ImportChina from '../ImportChina'
import ProductThai from '../ProductThai'
import HistoryThai from '../HistoryThai'
import ImportThai from '../ImportThai'
import Customer from '../Customer'
import Setting from '../Setting'
import SetAddress from '../SetAddress'
import Calculate from '../Calculate'
import PrintExpress from '../PrintExpress'
import Warehouse from '../Warehouse'
import Staff from '../Staff'

export default () => {
  const history = useHistory()
  const token = store.get('token')
  const user = store.get('user')
  const admin = user && user.role === 'admin'

  const checkToken = async () => {
    if (!token) return history.push('/login')
    const res = await http.get('user/me')
    if (res.data) store.set('user', res.data)
  }

  useEffect(() => {
    checkToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Layout
      content={
        <Switch>
          <Route exact path="/" component={ProductChina} />

          <Route exact path="/product-china" component={ProductChina} />
          <Route exact path="/history-china" component={HistoryChina} />
          <Route exact path="/import-china" component={ImportChina} />

          <Route exact path="/product-thai" component={ProductThai} />
          <Route exact path="/history-thai" component={HistoryThai} />
          <Route exact path="/import-thai" component={ImportThai} />

          {admin && <Route exact path="/customer" component={Customer} />}
          <Route exact path="/setting" component={Setting} />
          <Route exact path="/set-address" component={SetAddress} />
          <Route exact path="/calculate" component={Calculate} />
          <Route exact path="/print-express" component={PrintExpress} />

          {admin && <Route exact path="/warehouse" component={Warehouse} />}
          {admin && <Route exact path="/staff" component={Staff} />}

          <Route path="/404" component={PageNotFound} />
          <Redirect from="*" to="/404" />
        </Switch>
      }
    />
  )
}
