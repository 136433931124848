import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  label: {
    paddingLeft: 3,
  },
  input: {
    '&:before': {
      borderColor: '#ff810d',
    },
    '&:after': {
      borderColor: '#ff810d',
    },
  },
}

const SelectInput = ({ options, classes, input: { input, meta }, label, ...rest }) => {
  return (
    <FormControl margin="normal" required fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        variant="outlined"
        {...input}
        {...rest}
        inputProps={{
          className: classes.input,
        }}
      >
        {options.map(option => (
          <MenuItem key={option.title || option} value={option.value || option}>
            {option.title || option}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <div style={{ color: '#dc3545', marginTop: 5 }}>{meta.error}</div>
      )}
    </FormControl>
  )
}

export default withStyles(styles)(SelectInput)
